<template>
  <main>
    <img @click="goMain" class="logo" src="@/assets/images/logo-with-text.svg" alt="MyArena Logo" />
    <!-- <img @click="goMain" class="logo" src="@/assets/images/xmas_logo.png" alt="MyArena Logo" /> -->
    <img class="error" src="@/assets/images/misc/404.png" alt="404 error" />

    <button class="main-btn" @click="goBack">Voltar</button>
  </main>
</template>

<script>
export default {
  methods: {
    goMain() {
      this.$router.push("/");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6.125rem;

  .logo {
    width: 5.9531rem;
    height: 7.125rem;
    cursor: pointer;
  }

  .error {
    width: 25.9319rem;
    height: 27.25rem;
  }

  button {
    width: 25rem;
  }
}
</style>
